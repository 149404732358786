
import DeploymentsSubNav from "@/components/Navigation/DeploymentsSubNav.vue";
import { defineComponent } from "vue";
import { useDeploymentStore } from "@/store/deployment";

export default defineComponent({
  name: "DeploymentVolumes",
  components: { DeploymentsSubNav },
  props: {
    deploymentId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      deploymentStore: useDeploymentStore(),
    };
  },
});
