/* tslint:disable */
/* eslint-disable */
/**
 * jfnetwork Tools
 * Internal tools to support our workflows with automatism and reporting
 *
 * The version of the OpenAPI document: 0.0.0-dev
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AlertStatus = {
    _0: '0',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4'
} as const;

export type AlertStatus = typeof AlertStatus[keyof typeof AlertStatus];


/**
 * 
 * @export
 * @interface Certificate
 */
export interface Certificate {
    /**
     * 
     * @type {number}
     * @memberof Certificate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'issuer'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Certificate
     */
    'coveredDomains'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof Certificate
     */
    'manual'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'validFromFormatted'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'validToFormatted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Certificate
     */
    'validDaysLeft'?: number | null;
}
/**
 * 
 * @export
 * @interface CheckEntity
 */
export interface CheckEntity {
    /**
     * 
     * @type {string}
     * @memberof CheckEntity
     */
    'entity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckEntity
     */
    'subEntity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckEntity
     */
    'parent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckEntity
     */
    'parentEntity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckEntity
     */
    'parentSubEntity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckEntity
     */
    'alertChannel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckEntity
     */
    'metric'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckEntity
     */
    'error'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CheckEntity
     */
    'errorCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckEntity
     */
    'alertOnErrorCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CheckEntity
     */
    'alerted'?: boolean;
    /**
     * 
     * @type {AlertStatus}
     * @memberof CheckEntity
     */
    'status'?: AlertStatus | null;
    /**
     * 
     * @type {string}
     * @memberof CheckEntity
     */
    'lastSeen'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckEntity
     */
    'metricId'?: string;
}
/**
 * 
 * @export
 * @interface CronJob
 */
export interface CronJob {
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'cron'?: string;
    /**
     * 
     * @type {number}
     * @memberof CronJob
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CronJob
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'command'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'workingDirectory'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CronJob
     */
    'arguments'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface Deployment
 */
export interface Deployment {
    /**
     * 
     * @type {number}
     * @memberof Deployment
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Deployment
     */
    'project'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Deployment
     */
    'environmentVariables'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Deployment
     */
    'domains'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Deployment
     */
    'cronJobs'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Deployment
     */
    'triggers'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Deployment
     */
    'volumes'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Deployment
     */
    'cluster'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Deployment
     */
    'production'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Deployment
     */
    'autoDeploy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'note'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Deployment
     */
    'startupProbe'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Deployment
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface DeploymentList
 */
export interface DeploymentList {
    /**
     * 
     * @type {number}
     * @memberof DeploymentList
     */
    'id'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeploymentList
     */
    'domains'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DeploymentList
     */
    'revision'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeploymentList
     */
    'production'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeploymentList
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface DeploymentListDomain
 */
export interface DeploymentListDomain {
    /**
     * 
     * @type {string}
     * @memberof DeploymentListDomain
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DeploymentListRevision
 */
export interface DeploymentListRevision {
    /**
     * 
     * @type {string}
     * @memberof DeploymentListRevision
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DeploymentProject
 */
export interface DeploymentProject {
    /**
     * 
     * @type {number}
     * @memberof DeploymentProject
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeploymentProject
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeploymentProject
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeploymentProject
     */
    'gitlabUrl'?: string;
}
/**
 * 
 * @export
 * @interface Domain
 */
export interface Domain {
    /**
     * 
     * @type {number}
     * @memberof Domain
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    'path'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Domain
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Domain
     */
    'servicePort'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    'firewall'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    'maxUploadSize'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Domain
     */
    'legacyHttp'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Domain
     */
    'allowHttp'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Domain
     */
    'internal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Domain
     */
    'maxRequestTime'?: number | null;
}
/**
 * 
 * @export
 * @interface DomainSearch
 */
export interface DomainSearch {
    /**
     * 
     * @type {number}
     * @memberof DomainSearch
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainSearch
     */
    'deployment'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainSearch
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DomainSearchDeployment
 */
export interface DomainSearchDeployment {
    /**
     * 
     * @type {number}
     * @memberof DomainSearchDeployment
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainSearchDeployment
     */
    'project'?: number;
}
/**
 * 
 * @export
 * @interface EnvironmentResponse
 */
export interface EnvironmentResponse {
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResponse
     */
    'version'?: string;
    /**
     * 
     * @type {InternalUser}
     * @memberof EnvironmentResponse
     */
    'user'?: InternalUser;
}
/**
 * 
 * @export
 * @interface EnvironmentVariable
 */
export interface EnvironmentVariable {
    /**
     * 
     * @type {number}
     * @memberof EnvironmentVariable
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentVariable
     */
    'deployment'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentVariable
     */
    'domain'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentVariable
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentVariable
     */
    'value'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EnvironmentVariable
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface InternalUser
 */
export interface InternalUser {
    /**
     * 
     * @type {string}
     * @memberof InternalUser
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InternalUser
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InternalUser
     */
    'guid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalUser
     */
    'mail'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalUser
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalUser
     */
    'imagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalUser
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalUser
     */
    'lastname'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalUser
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalUser
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUser
     */
    'salt'?: string | null;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'gitlabUrl'?: string;
}
/**
 * 
 * @export
 * @interface ProjectResponse
 */
export interface ProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'avatar'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectSearch
 */
export interface ProjectSearch {
    /**
     * 
     * @type {number}
     * @memberof ProjectSearch
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectSearch
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSearch
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectSearch
     */
    'gitlabUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectSearch
     */
    'deploymentCount'?: number;
}
/**
 * 
 * @export
 * @interface ProjectSearchProject
 */
export interface ProjectSearchProject {
    /**
     * 
     * @type {number}
     * @memberof ProjectSearchProject
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectSearchProject
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ResponseError
 */
export interface ResponseError {
    /**
     * 
     * @type {string}
     * @memberof ResponseError
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseError
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TimeSpentIssue
 */
export interface TimeSpentIssue {
    /**
     * 
     * @type {string}
     * @memberof TimeSpentIssue
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeSpentIssue
     */
    'path'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeSpentIssue
     */
    'spent'?: string | null;
}
/**
 * 
 * @export
 * @interface TimeSpentResponse
 */
export interface TimeSpentResponse {
    /**
     * 
     * @type {string}
     * @memberof TimeSpentResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof TimeSpentResponse
     */
    'timeSpent'?: object | null;
    /**
     * 
     * @type {Array<TimeSpentIssue>}
     * @memberof TimeSpentResponse
     */
    'issues'?: Array<TimeSpentIssue>;
}
/**
 * 
 * @export
 * @interface Trigger
 */
export interface Trigger {
    /**
     * 
     * @type {string}
     * @memberof Trigger
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Trigger
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Trigger
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Trigger
     */
    'command'?: string;
    /**
     * 
     * @type {string}
     * @memberof Trigger
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof Trigger
     */
    'workingDirectory'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Trigger
     */
    'arguments'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Trigger
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'field'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface Volume
 */
export interface Volume {
    /**
     * 
     * @type {number}
     * @memberof Volume
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Volume
     */
    'mountPath'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Volume
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Volume
     */
    'target'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Volume
     */
    'nfsConnection'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Volume
     */
    'backup'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Volume
     */
    'backupStrategy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Volume
     */
    'backupDays'?: number | null;
}

/**
 * AlertsApi - axios parameter creator
 * @export
 */
export const AlertsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete an alert, that hasn\'t been seen for a while. You cannot delete known alerts as the alert would be instantly recreated.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppAlertDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAppAlertDelete', 'id', id)
            const localVarPath = `/api/alerts/silence/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists all alerts known by the alerting system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppAlertList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mute or unmute an alert
         * @param {CheckEntity} checkEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppAlertSilence: async (checkEntity: CheckEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkEntity' is not null or undefined
            assertParamExists('postAppAlertSilence', 'checkEntity', checkEntity)
            const localVarPath = `/api/alerts/silence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertsApi - functional programming interface
 * @export
 */
export const AlertsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlertsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete an alert, that hasn\'t been seen for a while. You cannot delete known alerts as the alert would be instantly recreated.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAppAlertDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAppAlertDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists all alerts known by the alerting system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppAlertList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CheckEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppAlertList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary mute or unmute an alert
         * @param {CheckEntity} checkEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppAlertSilence(checkEntity: CheckEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppAlertSilence(checkEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AlertsApi - factory interface
 * @export
 */
export const AlertsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlertsApiFp(configuration)
    return {
        /**
         * 
         * @summary delete an alert, that hasn\'t been seen for a while. You cannot delete known alerts as the alert would be instantly recreated.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppAlertDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAppAlertDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists all alerts known by the alerting system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppAlertList(options?: any): AxiosPromise<Array<CheckEntity>> {
            return localVarFp.getAppAlertList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary mute or unmute an alert
         * @param {CheckEntity} checkEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppAlertSilence(checkEntity: CheckEntity, options?: any): AxiosPromise<void> {
            return localVarFp.postAppAlertSilence(checkEntity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlertsApi - object-oriented interface
 * @export
 * @class AlertsApi
 * @extends {BaseAPI}
 */
export class AlertsApi extends BaseAPI {
    /**
     * 
     * @summary delete an alert, that hasn\'t been seen for a while. You cannot delete known alerts as the alert would be instantly recreated.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public deleteAppAlertDelete(id: string, options?: AxiosRequestConfig) {
        return AlertsApiFp(this.configuration).deleteAppAlertDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists all alerts known by the alerting system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public getAppAlertList(options?: AxiosRequestConfig) {
        return AlertsApiFp(this.configuration).getAppAlertList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary mute or unmute an alert
     * @param {CheckEntity} checkEntity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public postAppAlertSilence(checkEntity: CheckEntity, options?: AxiosRequestConfig) {
        return AlertsApiFp(this.configuration).postAppAlertSilence(checkEntity, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get all internal projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytics/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get time spent analytics for employees
         * @param {string} from 
         * @param {string} to 
         * @param {Array<number>} [projects] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsTimeSpent: async (from: string, to: string, projects?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getAnalyticsTimeSpent', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getAnalyticsTimeSpent', 'to', to)
            const localVarPath = `/api/analytics/time-spent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (projects) {
                localVarQueryParameter['projects'] = projects.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get all internal projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyticsProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get time spent analytics for employees
         * @param {string} from 
         * @param {string} to 
         * @param {Array<number>} [projects] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyticsTimeSpent(from: string, to: string, projects?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSpentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsTimeSpent(from, to, projects, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @summary get all internal projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsProjects(options?: any): AxiosPromise<Array<ProjectResponse>> {
            return localVarFp.getAnalyticsProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get time spent analytics for employees
         * @param {string} from 
         * @param {string} to 
         * @param {Array<number>} [projects] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsTimeSpent(from: string, to: string, projects?: Array<number>, options?: any): AxiosPromise<Array<TimeSpentResponse>> {
            return localVarFp.getAnalyticsTimeSpent(from, to, projects, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
    /**
     * 
     * @summary get all internal projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAnalyticsProjects(options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).getAnalyticsProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get time spent analytics for employees
     * @param {string} from 
     * @param {string} to 
     * @param {Array<number>} [projects] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAnalyticsTimeSpent(from: string, to: string, projects?: Array<number>, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).getAnalyticsTimeSpent(from, to, projects, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CertificatesApi - axios parameter creator
 * @export
 */
export const CertificatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary remove a certificate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCertificatesRemove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCertificatesRemove', 'id', id)
            const localVarPath = `/api/certificates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all certificates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary add a certificate
         * @param {any} [certificate] 
         * @param {any} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCertificatesAdd: async (certificate?: any, key?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/certificates/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (certificate !== undefined) { 
                localVarFormParams.append('certificate', new Blob([JSON.stringify(certificate)], { type: "application/json", }));
            }
    
            if (key !== undefined) { 
                localVarFormParams.append('key', new Blob([JSON.stringify(key)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary renew a certificate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCertificatesRenew: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putCertificatesRenew', 'id', id)
            const localVarPath = `/api/certificates/renew/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertificatesApi - functional programming interface
 * @export
 */
export const CertificatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CertificatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary remove a certificate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCertificatesRemove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCertificatesRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all certificates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertificates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Certificate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertificates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary add a certificate
         * @param {any} [certificate] 
         * @param {any} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCertificatesAdd(certificate?: any, key?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCertificatesAdd(certificate, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary renew a certificate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCertificatesRenew(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCertificatesRenew(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CertificatesApi - factory interface
 * @export
 */
export const CertificatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CertificatesApiFp(configuration)
    return {
        /**
         * 
         * @summary remove a certificate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCertificatesRemove(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCertificatesRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all certificates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificates(options?: any): AxiosPromise<Array<Certificate>> {
            return localVarFp.getCertificates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary add a certificate
         * @param {any} [certificate] 
         * @param {any} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCertificatesAdd(certificate?: any, key?: any, options?: any): AxiosPromise<void> {
            return localVarFp.postCertificatesAdd(certificate, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary renew a certificate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCertificatesRenew(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.putCertificatesRenew(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CertificatesApi - object-oriented interface
 * @export
 * @class CertificatesApi
 * @extends {BaseAPI}
 */
export class CertificatesApi extends BaseAPI {
    /**
     * 
     * @summary remove a certificate
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificatesApi
     */
    public deleteCertificatesRemove(id: number, options?: AxiosRequestConfig) {
        return CertificatesApiFp(this.configuration).deleteCertificatesRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all certificates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificatesApi
     */
    public getCertificates(options?: AxiosRequestConfig) {
        return CertificatesApiFp(this.configuration).getCertificates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary add a certificate
     * @param {any} [certificate] 
     * @param {any} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificatesApi
     */
    public postCertificatesAdd(certificate?: any, key?: any, options?: AxiosRequestConfig) {
        return CertificatesApiFp(this.configuration).postCertificatesAdd(certificate, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary renew a certificate
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificatesApi
     */
    public putCertificatesRenew(id: number, options?: AxiosRequestConfig) {
        return CertificatesApiFp(this.configuration).putCertificatesRenew(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CronJobsApi - axios parameter creator
 * @export
 */
export const CronJobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete a cronjob
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCronjobsDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCronjobsDelete', 'id', id)
            const localVarPath = `/api/cronjob/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of all cronjobs of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCronjobsList: async (deploymentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('getCronjobsList', 'deploymentId', deploymentId)
            const localVarPath = `/api/cronjobs/{deploymentId}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a cronjob
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCronjobsRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCronjobsRead', 'id', id)
            const localVarPath = `/api/cronjob/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create new cronjob
         * @param {CronJob} cronJob 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCronjobsCreate: async (cronJob: CronJob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cronJob' is not null or undefined
            assertParamExists('postCronjobsCreate', 'cronJob', cronJob)
            const localVarPath = `/api/cronjob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cronJob, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary replace a cronjob
         * @param {CronJob} cronJob 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCronjobsReplace: async (cronJob: CronJob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cronJob' is not null or undefined
            assertParamExists('putCronjobsReplace', 'cronJob', cronJob)
            const localVarPath = `/api/cronjob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cronJob, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CronJobsApi - functional programming interface
 * @export
 */
export const CronJobsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CronJobsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete a cronjob
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCronjobsDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCronjobsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list of all cronjobs of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCronjobsList(deploymentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CronJob>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCronjobsList(deploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a cronjob
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCronjobsRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CronJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCronjobsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create new cronjob
         * @param {CronJob} cronJob 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCronjobsCreate(cronJob: CronJob, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CronJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCronjobsCreate(cronJob, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary replace a cronjob
         * @param {CronJob} cronJob 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCronjobsReplace(cronJob: CronJob, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CronJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCronjobsReplace(cronJob, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CronJobsApi - factory interface
 * @export
 */
export const CronJobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CronJobsApiFp(configuration)
    return {
        /**
         * 
         * @summary delete a cronjob
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCronjobsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCronjobsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of all cronjobs of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCronjobsList(deploymentId: number, options?: any): AxiosPromise<Array<CronJob>> {
            return localVarFp.getCronjobsList(deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a cronjob
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCronjobsRead(id: number, options?: any): AxiosPromise<CronJob> {
            return localVarFp.getCronjobsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create new cronjob
         * @param {CronJob} cronJob 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCronjobsCreate(cronJob: CronJob, options?: any): AxiosPromise<CronJob> {
            return localVarFp.postCronjobsCreate(cronJob, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary replace a cronjob
         * @param {CronJob} cronJob 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCronjobsReplace(cronJob: CronJob, options?: any): AxiosPromise<CronJob> {
            return localVarFp.putCronjobsReplace(cronJob, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CronJobsApi - object-oriented interface
 * @export
 * @class CronJobsApi
 * @extends {BaseAPI}
 */
export class CronJobsApi extends BaseAPI {
    /**
     * 
     * @summary delete a cronjob
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronJobsApi
     */
    public deleteCronjobsDelete(id: number, options?: AxiosRequestConfig) {
        return CronJobsApiFp(this.configuration).deleteCronjobsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of all cronjobs of the deployment
     * @param {number} deploymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronJobsApi
     */
    public getCronjobsList(deploymentId: number, options?: AxiosRequestConfig) {
        return CronJobsApiFp(this.configuration).getCronjobsList(deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a cronjob
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronJobsApi
     */
    public getCronjobsRead(id: number, options?: AxiosRequestConfig) {
        return CronJobsApiFp(this.configuration).getCronjobsRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create new cronjob
     * @param {CronJob} cronJob 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronJobsApi
     */
    public postCronjobsCreate(cronJob: CronJob, options?: AxiosRequestConfig) {
        return CronJobsApiFp(this.configuration).postCronjobsCreate(cronJob, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary replace a cronjob
     * @param {CronJob} cronJob 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronJobsApi
     */
    public putCronjobsReplace(cronJob: CronJob, options?: AxiosRequestConfig) {
        return CronJobsApiFp(this.configuration).putCronjobsReplace(cronJob, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocJsonGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doc.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocJsonGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocJsonGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocJsonGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiDocJsonGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiDocJsonGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiDocJsonGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeploymentsApi - axios parameter creator
 * @export
 */
export const DeploymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete a deployment
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeploymentsDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDeploymentsDelete', 'id', id)
            const localVarPath = `/api/deployment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of all deployments of the project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentsList: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getDeploymentsList', 'projectId', projectId)
            const localVarPath = `/api/deployments/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a deployment
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentsRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeploymentsRead', 'id', id)
            const localVarPath = `/api/deployment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create new deployment
         * @param {Deployment} deployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeploymentsCreate: async (deployment: Deployment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deployment' is not null or undefined
            assertParamExists('postDeploymentsCreate', 'deployment', deployment)
            const localVarPath = `/api/deployment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deployment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary replace a deployment
         * @param {Deployment} deployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeploymentsReplace: async (deployment: Deployment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deployment' is not null or undefined
            assertParamExists('putDeploymentsReplace', 'deployment', deployment)
            const localVarPath = `/api/deployment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deployment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeploymentsApi - functional programming interface
 * @export
 */
export const DeploymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeploymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete a deployment
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeploymentsDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeploymentsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list of all deployments of the project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeploymentsList(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Deployment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeploymentsList(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a deployment
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeploymentsRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deployment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeploymentsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create new deployment
         * @param {Deployment} deployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeploymentsCreate(deployment: Deployment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deployment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeploymentsCreate(deployment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary replace a deployment
         * @param {Deployment} deployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDeploymentsReplace(deployment: Deployment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deployment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDeploymentsReplace(deployment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeploymentsApi - factory interface
 * @export
 */
export const DeploymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeploymentsApiFp(configuration)
    return {
        /**
         * 
         * @summary delete a deployment
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeploymentsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDeploymentsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of all deployments of the project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentsList(projectId: number, options?: any): AxiosPromise<Array<Deployment>> {
            return localVarFp.getDeploymentsList(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a deployment
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentsRead(id: number, options?: any): AxiosPromise<Deployment> {
            return localVarFp.getDeploymentsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create new deployment
         * @param {Deployment} deployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeploymentsCreate(deployment: Deployment, options?: any): AxiosPromise<Deployment> {
            return localVarFp.postDeploymentsCreate(deployment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary replace a deployment
         * @param {Deployment} deployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeploymentsReplace(deployment: Deployment, options?: any): AxiosPromise<Deployment> {
            return localVarFp.putDeploymentsReplace(deployment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeploymentsApi - object-oriented interface
 * @export
 * @class DeploymentsApi
 * @extends {BaseAPI}
 */
export class DeploymentsApi extends BaseAPI {
    /**
     * 
     * @summary delete a deployment
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public deleteDeploymentsDelete(id: number, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).deleteDeploymentsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of all deployments of the project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public getDeploymentsList(projectId: number, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).getDeploymentsList(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a deployment
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public getDeploymentsRead(id: number, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).getDeploymentsRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create new deployment
     * @param {Deployment} deployment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public postDeploymentsCreate(deployment: Deployment, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).postDeploymentsCreate(deployment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary replace a deployment
     * @param {Deployment} deployment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public putDeploymentsReplace(deployment: Deployment, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).putDeploymentsReplace(deployment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeploymentsOLDApi - axios parameter creator
 * @export
 */
export const DeploymentsOLDApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get details to a deployment
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDeploymentsOldDeployment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeploymentsOldDeployment', 'id', id)
            const localVarPath = `/api/deployment_old/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get overview of all deployments of given project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDeploymentsOldDeployments: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getDeploymentsOldDeployments', 'projectId', projectId)
            const localVarPath = `/api/deployment_olds/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeploymentsOLDApi - functional programming interface
 * @export
 */
export const DeploymentsOLDApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeploymentsOLDApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get details to a deployment
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDeploymentsOldDeployment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deployment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeploymentsOldDeployment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get overview of all deployments of given project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDeploymentsOldDeployments(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeploymentsOldDeployments(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeploymentsOLDApi - factory interface
 * @export
 */
export const DeploymentsOLDApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeploymentsOLDApiFp(configuration)
    return {
        /**
         * 
         * @summary get details to a deployment
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDeploymentsOldDeployment(id: number, options?: any): AxiosPromise<Deployment> {
            return localVarFp.getDeploymentsOldDeployment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get overview of all deployments of given project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDeploymentsOldDeployments(projectId: number, options?: any): AxiosPromise<Array<DeploymentList>> {
            return localVarFp.getDeploymentsOldDeployments(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeploymentsOLDApi - object-oriented interface
 * @export
 * @class DeploymentsOLDApi
 * @extends {BaseAPI}
 */
export class DeploymentsOLDApi extends BaseAPI {
    /**
     * 
     * @summary get details to a deployment
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeploymentsOLDApi
     */
    public getDeploymentsOldDeployment(id: number, options?: AxiosRequestConfig) {
        return DeploymentsOLDApiFp(this.configuration).getDeploymentsOldDeployment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get overview of all deployments of given project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeploymentsOLDApi
     */
    public getDeploymentsOldDeployments(projectId: number, options?: AxiosRequestConfig) {
        return DeploymentsOLDApiFp(this.configuration).getDeploymentsOldDeployments(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DomainsApi - axios parameter creator
 * @export
 */
export const DomainsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete a domain
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDomainsDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDomainsDelete', 'id', id)
            const localVarPath = `/api/domain/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all configured domains for autocomplete search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomains: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of all domains of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainsList: async (deploymentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('getDomainsList', 'deploymentId', deploymentId)
            const localVarPath = `/api/domains/{deploymentId}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a domain
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainsRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDomainsRead', 'id', id)
            const localVarPath = `/api/domain/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create new domain
         * @param {Domain} domain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDomainsCreate: async (domain: Domain, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domain' is not null or undefined
            assertParamExists('postDomainsCreate', 'domain', domain)
            const localVarPath = `/api/domain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(domain, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary replace a domain
         * @param {Domain} domain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDomainsReplace: async (domain: Domain, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domain' is not null or undefined
            assertParamExists('putDomainsReplace', 'domain', domain)
            const localVarPath = `/api/domain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(domain, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DomainsApi - functional programming interface
 * @export
 */
export const DomainsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DomainsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete a domain
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDomainsDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDomainsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all configured domains for autocomplete search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDomains(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DomainSearch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDomains(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list of all domains of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDomainsList(deploymentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Domain>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDomainsList(deploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a domain
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDomainsRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Domain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDomainsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create new domain
         * @param {Domain} domain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDomainsCreate(domain: Domain, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Domain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDomainsCreate(domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary replace a domain
         * @param {Domain} domain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDomainsReplace(domain: Domain, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Domain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDomainsReplace(domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DomainsApi - factory interface
 * @export
 */
export const DomainsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DomainsApiFp(configuration)
    return {
        /**
         * 
         * @summary delete a domain
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDomainsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDomainsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all configured domains for autocomplete search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomains(options?: any): AxiosPromise<Array<DomainSearch>> {
            return localVarFp.getDomains(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of all domains of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainsList(deploymentId: number, options?: any): AxiosPromise<Array<Domain>> {
            return localVarFp.getDomainsList(deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a domain
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainsRead(id: number, options?: any): AxiosPromise<Domain> {
            return localVarFp.getDomainsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create new domain
         * @param {Domain} domain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDomainsCreate(domain: Domain, options?: any): AxiosPromise<Domain> {
            return localVarFp.postDomainsCreate(domain, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary replace a domain
         * @param {Domain} domain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDomainsReplace(domain: Domain, options?: any): AxiosPromise<Domain> {
            return localVarFp.putDomainsReplace(domain, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DomainsApi - object-oriented interface
 * @export
 * @class DomainsApi
 * @extends {BaseAPI}
 */
export class DomainsApi extends BaseAPI {
    /**
     * 
     * @summary delete a domain
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainsApi
     */
    public deleteDomainsDelete(id: number, options?: AxiosRequestConfig) {
        return DomainsApiFp(this.configuration).deleteDomainsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all configured domains for autocomplete search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainsApi
     */
    public getDomains(options?: AxiosRequestConfig) {
        return DomainsApiFp(this.configuration).getDomains(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of all domains of the deployment
     * @param {number} deploymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainsApi
     */
    public getDomainsList(deploymentId: number, options?: AxiosRequestConfig) {
        return DomainsApiFp(this.configuration).getDomainsList(deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a domain
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainsApi
     */
    public getDomainsRead(id: number, options?: AxiosRequestConfig) {
        return DomainsApiFp(this.configuration).getDomainsRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create new domain
     * @param {Domain} domain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainsApi
     */
    public postDomainsCreate(domain: Domain, options?: AxiosRequestConfig) {
        return DomainsApiFp(this.configuration).postDomainsCreate(domain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary replace a domain
     * @param {Domain} domain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainsApi
     */
    public putDomainsReplace(domain: Domain, options?: AxiosRequestConfig) {
        return DomainsApiFp(this.configuration).putDomainsReplace(domain, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnvironmentApi - axios parameter creator
 * @export
 */
export const EnvironmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get config for frontend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppEnvironmentLogin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/environment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnvironmentApi - functional programming interface
 * @export
 */
export const EnvironmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnvironmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get config for frontend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppEnvironmentLogin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppEnvironmentLogin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnvironmentApi - factory interface
 * @export
 */
export const EnvironmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnvironmentApiFp(configuration)
    return {
        /**
         * 
         * @summary get config for frontend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppEnvironmentLogin(options?: any): AxiosPromise<EnvironmentResponse> {
            return localVarFp.getAppEnvironmentLogin(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnvironmentApi - object-oriented interface
 * @export
 * @class EnvironmentApi
 * @extends {BaseAPI}
 */
export class EnvironmentApi extends BaseAPI {
    /**
     * 
     * @summary get config for frontend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentApi
     */
    public getAppEnvironmentLogin(options?: AxiosRequestConfig) {
        return EnvironmentApiFp(this.configuration).getAppEnvironmentLogin(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnvironmentVariablesApi - axios parameter creator
 * @export
 */
export const EnvironmentVariablesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete a environment variable
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironmentVariablesDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEnvironmentVariablesDelete', 'id', id)
            const localVarPath = `/api/environment_variable/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of all environment variables of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentVariablesListByDeployment: async (deploymentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('getEnvironmentVariablesListByDeployment', 'deploymentId', deploymentId)
            const localVarPath = `/api/environment_variables/by_deployment/{deploymentId}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of all environment variables of the domain
         * @param {number} domainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentVariablesListByDomain: async (domainId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domainId' is not null or undefined
            assertParamExists('getEnvironmentVariablesListByDomain', 'domainId', domainId)
            const localVarPath = `/api/environment_variables/by_domain/{domainId}`
                .replace(`{${"domainId"}}`, encodeURIComponent(String(domainId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a environment variable
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentVariablesRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEnvironmentVariablesRead', 'id', id)
            const localVarPath = `/api/environment_variable/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create new environment variable
         * @param {EnvironmentVariable} environmentVariable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEnvironmentVariablesCreate: async (environmentVariable: EnvironmentVariable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'environmentVariable' is not null or undefined
            assertParamExists('postEnvironmentVariablesCreate', 'environmentVariable', environmentVariable)
            const localVarPath = `/api/environment_variable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(environmentVariable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary replace a environment variable
         * @param {EnvironmentVariable} environmentVariable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEnvironmentVariablesReplace: async (environmentVariable: EnvironmentVariable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'environmentVariable' is not null or undefined
            assertParamExists('putEnvironmentVariablesReplace', 'environmentVariable', environmentVariable)
            const localVarPath = `/api/environment_variable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(environmentVariable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnvironmentVariablesApi - functional programming interface
 * @export
 */
export const EnvironmentVariablesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnvironmentVariablesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete a environment variable
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEnvironmentVariablesDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEnvironmentVariablesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list of all environment variables of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnvironmentVariablesListByDeployment(deploymentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnvironmentVariable>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnvironmentVariablesListByDeployment(deploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list of all environment variables of the domain
         * @param {number} domainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnvironmentVariablesListByDomain(domainId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnvironmentVariable>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnvironmentVariablesListByDomain(domainId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a environment variable
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnvironmentVariablesRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentVariable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnvironmentVariablesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create new environment variable
         * @param {EnvironmentVariable} environmentVariable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEnvironmentVariablesCreate(environmentVariable: EnvironmentVariable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentVariable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEnvironmentVariablesCreate(environmentVariable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary replace a environment variable
         * @param {EnvironmentVariable} environmentVariable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEnvironmentVariablesReplace(environmentVariable: EnvironmentVariable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentVariable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEnvironmentVariablesReplace(environmentVariable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnvironmentVariablesApi - factory interface
 * @export
 */
export const EnvironmentVariablesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnvironmentVariablesApiFp(configuration)
    return {
        /**
         * 
         * @summary delete a environment variable
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironmentVariablesDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEnvironmentVariablesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of all environment variables of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentVariablesListByDeployment(deploymentId: number, options?: any): AxiosPromise<Array<EnvironmentVariable>> {
            return localVarFp.getEnvironmentVariablesListByDeployment(deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of all environment variables of the domain
         * @param {number} domainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentVariablesListByDomain(domainId: number, options?: any): AxiosPromise<Array<EnvironmentVariable>> {
            return localVarFp.getEnvironmentVariablesListByDomain(domainId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a environment variable
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentVariablesRead(id: number, options?: any): AxiosPromise<EnvironmentVariable> {
            return localVarFp.getEnvironmentVariablesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create new environment variable
         * @param {EnvironmentVariable} environmentVariable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEnvironmentVariablesCreate(environmentVariable: EnvironmentVariable, options?: any): AxiosPromise<EnvironmentVariable> {
            return localVarFp.postEnvironmentVariablesCreate(environmentVariable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary replace a environment variable
         * @param {EnvironmentVariable} environmentVariable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEnvironmentVariablesReplace(environmentVariable: EnvironmentVariable, options?: any): AxiosPromise<EnvironmentVariable> {
            return localVarFp.putEnvironmentVariablesReplace(environmentVariable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnvironmentVariablesApi - object-oriented interface
 * @export
 * @class EnvironmentVariablesApi
 * @extends {BaseAPI}
 */
export class EnvironmentVariablesApi extends BaseAPI {
    /**
     * 
     * @summary delete a environment variable
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentVariablesApi
     */
    public deleteEnvironmentVariablesDelete(id: number, options?: AxiosRequestConfig) {
        return EnvironmentVariablesApiFp(this.configuration).deleteEnvironmentVariablesDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of all environment variables of the deployment
     * @param {number} deploymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentVariablesApi
     */
    public getEnvironmentVariablesListByDeployment(deploymentId: number, options?: AxiosRequestConfig) {
        return EnvironmentVariablesApiFp(this.configuration).getEnvironmentVariablesListByDeployment(deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of all environment variables of the domain
     * @param {number} domainId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentVariablesApi
     */
    public getEnvironmentVariablesListByDomain(domainId: number, options?: AxiosRequestConfig) {
        return EnvironmentVariablesApiFp(this.configuration).getEnvironmentVariablesListByDomain(domainId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a environment variable
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentVariablesApi
     */
    public getEnvironmentVariablesRead(id: number, options?: AxiosRequestConfig) {
        return EnvironmentVariablesApiFp(this.configuration).getEnvironmentVariablesRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create new environment variable
     * @param {EnvironmentVariable} environmentVariable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentVariablesApi
     */
    public postEnvironmentVariablesCreate(environmentVariable: EnvironmentVariable, options?: AxiosRequestConfig) {
        return EnvironmentVariablesApiFp(this.configuration).postEnvironmentVariablesCreate(environmentVariable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary replace a environment variable
     * @param {EnvironmentVariable} environmentVariable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentVariablesApi
     */
    public putEnvironmentVariablesReplace(environmentVariable: EnvironmentVariable, options?: AxiosRequestConfig) {
        return EnvironmentVariablesApiFp(this.configuration).putEnvironmentVariablesReplace(environmentVariable, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary reset an active project or delete it if it is no longer present in GitLab
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectsRemove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProjectsRemove', 'id', id)
            const localVarPath = `/api/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all projects for search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get details of a project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsDetails: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectsDetails', 'id', id)
            const localVarPath = `/api/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary reset an active project or delete it if it is no longer present in GitLab
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectsRemove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectsRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all projects for search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectSearch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get details of a project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsDetails(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary reset an active project or delete it if it is no longer present in GitLab
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectsRemove(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectsRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all projects for search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(options?: any): AxiosPromise<Array<ProjectSearch>> {
            return localVarFp.getProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get details of a project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsDetails(id: number, options?: any): AxiosPromise<Project> {
            return localVarFp.getProjectsDetails(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @summary reset an active project or delete it if it is no longer present in GitLab
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProjectsRemove(id: number, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProjectsRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all projects for search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjects(options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get details of a project
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectsDetails(id: number, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectsDetails(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TriggersApi - axios parameter creator
 * @export
 */
export const TriggersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete a trigger
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTriggersDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTriggersDelete', 'id', id)
            const localVarPath = `/api/trigger/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of all triggers of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggersList: async (deploymentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('getTriggersList', 'deploymentId', deploymentId)
            const localVarPath = `/api/triggers/{deploymentId}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a trigger
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggersRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTriggersRead', 'id', id)
            const localVarPath = `/api/trigger/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create new trigger
         * @param {Trigger} trigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTriggersCreate: async (trigger: Trigger, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trigger' is not null or undefined
            assertParamExists('postTriggersCreate', 'trigger', trigger)
            const localVarPath = `/api/trigger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trigger, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary replace a trigger
         * @param {Trigger} trigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTriggersReplace: async (trigger: Trigger, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trigger' is not null or undefined
            assertParamExists('putTriggersReplace', 'trigger', trigger)
            const localVarPath = `/api/trigger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trigger, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TriggersApi - functional programming interface
 * @export
 */
export const TriggersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TriggersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete a trigger
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTriggersDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTriggersDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list of all triggers of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTriggersList(deploymentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Trigger>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTriggersList(deploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a trigger
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTriggersRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trigger>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTriggersRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create new trigger
         * @param {Trigger} trigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTriggersCreate(trigger: Trigger, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trigger>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTriggersCreate(trigger, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary replace a trigger
         * @param {Trigger} trigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTriggersReplace(trigger: Trigger, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trigger>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTriggersReplace(trigger, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TriggersApi - factory interface
 * @export
 */
export const TriggersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TriggersApiFp(configuration)
    return {
        /**
         * 
         * @summary delete a trigger
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTriggersDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTriggersDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of all triggers of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggersList(deploymentId: number, options?: any): AxiosPromise<Array<Trigger>> {
            return localVarFp.getTriggersList(deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a trigger
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggersRead(id: number, options?: any): AxiosPromise<Trigger> {
            return localVarFp.getTriggersRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create new trigger
         * @param {Trigger} trigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTriggersCreate(trigger: Trigger, options?: any): AxiosPromise<Trigger> {
            return localVarFp.postTriggersCreate(trigger, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary replace a trigger
         * @param {Trigger} trigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTriggersReplace(trigger: Trigger, options?: any): AxiosPromise<Trigger> {
            return localVarFp.putTriggersReplace(trigger, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TriggersApi - object-oriented interface
 * @export
 * @class TriggersApi
 * @extends {BaseAPI}
 */
export class TriggersApi extends BaseAPI {
    /**
     * 
     * @summary delete a trigger
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApi
     */
    public deleteTriggersDelete(id: number, options?: AxiosRequestConfig) {
        return TriggersApiFp(this.configuration).deleteTriggersDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of all triggers of the deployment
     * @param {number} deploymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApi
     */
    public getTriggersList(deploymentId: number, options?: AxiosRequestConfig) {
        return TriggersApiFp(this.configuration).getTriggersList(deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a trigger
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApi
     */
    public getTriggersRead(id: number, options?: AxiosRequestConfig) {
        return TriggersApiFp(this.configuration).getTriggersRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create new trigger
     * @param {Trigger} trigger 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApi
     */
    public postTriggersCreate(trigger: Trigger, options?: AxiosRequestConfig) {
        return TriggersApiFp(this.configuration).postTriggersCreate(trigger, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary replace a trigger
     * @param {Trigger} trigger 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApi
     */
    public putTriggersReplace(trigger: Trigger, options?: AxiosRequestConfig) {
        return TriggersApiFp(this.configuration).putTriggersReplace(trigger, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VolumesApi - axios parameter creator
 * @export
 */
export const VolumesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete a volume
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVolumesDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteVolumesDelete', 'id', id)
            const localVarPath = `/api/volume/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of all volumes of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumesList: async (deploymentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('getVolumesList', 'deploymentId', deploymentId)
            const localVarPath = `/api/volumes/{deploymentId}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a volume
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumesRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVolumesRead', 'id', id)
            const localVarPath = `/api/volume/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create new volume
         * @param {Volume} volume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVolumesCreate: async (volume: Volume, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'volume' is not null or undefined
            assertParamExists('postVolumesCreate', 'volume', volume)
            const localVarPath = `/api/volume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(volume, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary replace a volume
         * @param {Volume} volume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVolumesReplace: async (volume: Volume, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'volume' is not null or undefined
            assertParamExists('putVolumesReplace', 'volume', volume)
            const localVarPath = `/api/volume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(volume, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VolumesApi - functional programming interface
 * @export
 */
export const VolumesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VolumesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete a volume
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVolumesDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVolumesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list of all volumes of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVolumesList(deploymentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Volume>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVolumesList(deploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a volume
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVolumesRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Volume>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVolumesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create new volume
         * @param {Volume} volume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postVolumesCreate(volume: Volume, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Volume>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postVolumesCreate(volume, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary replace a volume
         * @param {Volume} volume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVolumesReplace(volume: Volume, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Volume>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVolumesReplace(volume, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VolumesApi - factory interface
 * @export
 */
export const VolumesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VolumesApiFp(configuration)
    return {
        /**
         * 
         * @summary delete a volume
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVolumesDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVolumesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of all volumes of the deployment
         * @param {number} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumesList(deploymentId: number, options?: any): AxiosPromise<Array<Volume>> {
            return localVarFp.getVolumesList(deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a volume
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumesRead(id: number, options?: any): AxiosPromise<Volume> {
            return localVarFp.getVolumesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create new volume
         * @param {Volume} volume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVolumesCreate(volume: Volume, options?: any): AxiosPromise<Volume> {
            return localVarFp.postVolumesCreate(volume, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary replace a volume
         * @param {Volume} volume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVolumesReplace(volume: Volume, options?: any): AxiosPromise<Volume> {
            return localVarFp.putVolumesReplace(volume, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VolumesApi - object-oriented interface
 * @export
 * @class VolumesApi
 * @extends {BaseAPI}
 */
export class VolumesApi extends BaseAPI {
    /**
     * 
     * @summary delete a volume
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VolumesApi
     */
    public deleteVolumesDelete(id: number, options?: AxiosRequestConfig) {
        return VolumesApiFp(this.configuration).deleteVolumesDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of all volumes of the deployment
     * @param {number} deploymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VolumesApi
     */
    public getVolumesList(deploymentId: number, options?: AxiosRequestConfig) {
        return VolumesApiFp(this.configuration).getVolumesList(deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a volume
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VolumesApi
     */
    public getVolumesRead(id: number, options?: AxiosRequestConfig) {
        return VolumesApiFp(this.configuration).getVolumesRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create new volume
     * @param {Volume} volume 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VolumesApi
     */
    public postVolumesCreate(volume: Volume, options?: AxiosRequestConfig) {
        return VolumesApiFp(this.configuration).postVolumesCreate(volume, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary replace a volume
     * @param {Volume} volume 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VolumesApi
     */
    public putVolumesReplace(volume: Volume, options?: AxiosRequestConfig) {
        return VolumesApiFp(this.configuration).putVolumesReplace(volume, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWebhook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWebhook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWebhook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWebhook(options?: any): AxiosPromise<void> {
            return localVarFp.postWebhook(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public postWebhook(options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).postWebhook(options).then((request) => request(this.axios, this.basePath));
    }
}


