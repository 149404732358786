
import { defineComponent } from "vue";
import { AnalyticsApi, ProjectResponse, TimeSpentResponse } from "@/api";
import IssueList from "@/components/IssueList.vue";
import { parseTime } from "@/tools/humanTime";

export default defineComponent({
  name: "TimeSpentView",
  components: { IssueList },
  data() {
    return {
      loading: false as boolean,
      dateFrom: "" as string,
      dateFromMenu: false as boolean,
      dateTo: "" as string,
      dateToMenu: false as boolean,
      result: null as null | Array<TimeSpentResponse>,
      selected: [],
      selectedProjects: [] as Array<number>,
      singleSelect: null,
      projects: null as null | Array<ProjectResponse>,
      headers: [
        { text: "Entwickler", value: "name", width: "150px" },
        { text: "Zeit", value: "timeSpent", width: "200px", align: "end" },
        { text: "Tickets", value: "issues" },
      ],
    };
  },
  computed: {
    totalTime(): string {
      return parseTime(
        this.selected.reduce(function (a: number, b: TimeSpentResponse) {
          return a + ((b.timeSpent as unknown as number) ?? 0);
        }, 0)
      );
    },
  },
  methods: {
    async loadData(): Promise<void> {
      this.selected = [];
      this.loading = true;
      try {
        this.result = (
          await new AnalyticsApi().getAnalyticsTimeSpent(
            this.dateFrom,
            this.dateTo,
            this.selectedProjects
          )
        ).data;
      } finally {
        this.loading = false;
      }
    },
    humanTime(time: number) {
      return parseTime(time);
    },
    removeProject(item: ProjectResponse) {
      const index = this.selectedProjects.indexOf(item.id as number);
      if (index >= 0) this.selectedProjects.splice(index, 1);
    },
  },
  async mounted() {
    this.projects = (await new AnalyticsApi().getAnalyticsProjects()).data;
  },
});
