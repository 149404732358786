import { defineStore } from "pinia";
import { EnvironmentApi, EnvironmentResponse } from "@/api";

export const useMainStore = defineStore("main", {
  state: () => ({
    env: null as EnvironmentResponse | null,
  }),
  actions: {
    async load(): Promise<void> {
      this.env = (await new EnvironmentApi().getAppEnvironmentLogin()).data;
    },
    async hasAccess(role: string): Promise<boolean> {
      if (this.env === null) {
        await this.load();
      }
      return this.env?.user?.roles?.includes(role) ?? false;
    },
  },
});
