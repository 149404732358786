
import { defineComponent, PropType } from "vue";
import { DataTableHeader } from "vuetify";
import { Deployment } from "@/api";

export default defineComponent({
  name: "DeploymentList",
  props: {
    deployments: {
      type: {} as PropType<Array<Deployment>>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "alert",
          align: "center",
          width: "50px",
        },
        {
          text: "Deployment",
          value: "id",
          width: "120px",
        },
        {
          text: "Note",
          value: "note",
        },
        {
          text: "Revision",
          value: "revision",
          align: "center",
        },
        {
          text: "Domains",
          value: "domains",
          align: "right",
        },
        {
          text: "Mode",
          value: "production",
          align: "center",
          width: "50px",
        },
        {
          text: "",
          value: "action",
          align: "right",
          width: "340px",
          filterable: false,
        },
      ] as Array<DataTableHeader>,
    };
  },
  methods: {
    remove(deploymentId: number) {
      console.log(deploymentId);
    },
  },
});
