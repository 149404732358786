<template>
  <svg
    :style="{ 'max-width': width }"
    class="mx-auto"
    viewBox="0 0 448.83914 144.15417"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(119.34219,-63.905051)">
      <g transform="matrix(0.35277777,0,0,-0.35277777,-119.34219,208.05922)">
        <g transform="scale(0.1)">
          <path
            class="black"
            d="M 7579.7,3101.08 H 6416.35 c -286.06,0 -530.16,-179.11 -610.27,-516.97 l -41.96,-175.03 h -377.6 l -87.73,-374.49 H 5676.4 L 5417.04,927.391 C 5375.08,752.352 5305.81,495.91 5305.81,495.91 h 36.47 891.87 c 0,0 41.08,252.371 83.04,431.481 l 259.37,1107.199 h 717.07 l 87.73,374.49 h -717.08 l 41.95,175.03 c 26.71,105.83 76.29,142.48 179.27,142.48 h 369.99 c 99.17,0 280.79,-13.95 280.79,-13.95 l 43.42,388.44"
          />
          <path
            class="black"
            d="m 7526.93,1053.05 c 29.74,180.06 -7.15,301.92 -135.66,301.92 H 7165.2 c -38.09,0 -67.81,-36.38 -90.43,-89.13 h -2.38 l 5.94,89.13 h -268.71 c 0,0 -8.53,-114.59 -21.62,-192.8 l -77.33,-469.248 c -13.1,-78.211 -45.57,-192.801 -45.57,-192.801 h 306.56 c 0,0 6.72,114.59 19.82,192.801 l 71.38,432.868 c 7.14,41.84 25,61.84 47.6,61.84 h 116.62 c 29.75,0 36.89,-23.64 30.93,-61.84 l -71.4,-432.868 c -13.08,-78.211 -32.47,-192.801 -32.47,-192.801 h 296.35 c 0,0 3.85,114.59 16.94,192.801 l 59.5,360.128"
          />
          <path
            class="black"
            d="m 8169.27,1120.33 -19.03,-118.22 h -196.35 l 19.04,118.22 c 8.33,49.12 21.42,67.3 53.56,67.3 h 110.65 c 32.13,0 40.46,-18.18 32.13,-67.3 z m 272.49,-9.08 c 26.17,158.23 0,243.72 -116.61,243.72 h -439.06 c -116.62,0 -168.98,-85.49 -195.15,-243.72 l -58.31,-356.488 c -28.55,-176.442 -2.37,-254.641 114.23,-254.641 h 565.19 l 42.46,157.961 2.93,10.898 c 0,0 -63.24,-1.531 -115.59,-1.531 h -284.38 c -38.07,0 -53.55,30.91 -44.02,87.301 l 13.09,80.039 h 470.01 l 45.21,276.461 v 0"
          />
          <path
            class="black"
            d="m 9210.78,1354.97 h -223.7 l 30.94,185.52 -283.2,-10.92 -28.56,-174.6 h -117.8 l -27.36,-167.34 h 117.8 l -72.59,-443.79 c -24.99,-150.969 23.8,-243.719 113.04,-243.719 h 362.92 l 41.92,166.301 c 0,0 -57.39,1.027 -88.34,1.027 h -115.41 c -32.12,0 -41.65,16.371 -34.5,63.653 l 73.77,456.528 h 223.7 l 27.37,167.34"
          />
          <path
            class="black"
            d="m 10395,1354.97 h -99.3 l -169.3,-545.65 h -2.4 l -7.1,545.65 H 9794.43 L 9615.95,809.32 h -2.39 l -8.32,389.23 c 0,25.46 -4.43,156.42 -4.43,156.42 h -281.74 c 0,0 0.59,-90.95 1.77,-156.42 l 9.52,-698.429 h 293.92 l 211.79,651.139 h 2.38 l 7.14,-651.139 h 290.31 l 259.1,854.849"
          />
          <path
            class="black"
            d="m 10935.1,1123.98 -64.3,-392.878 c -7.1,-43.653 -22.6,-63.653 -49.9,-63.653 h -121.4 c -27.4,0 -35.7,20 -28.6,63.653 l 64.3,392.878 c 7.1,43.65 21.4,63.65 48.8,63.65 h 121.3 c 27.4,0 36.9,-20 29.8,-63.65 z m 277.2,-16.37 c 25,154.6 6,247.36 -114.2,247.36 H 10646 c -120.2,0 -170.2,-92.76 -195.2,-247.36 l -58.3,-360.13 c -25,-154.609 -5.9,-247.359 114.2,-247.359 h 452.2 c 120.2,0 170.1,92.75 195.1,247.359 l 58.3,360.13 v 0"
          />
          <path
            class="black"
            d="m 11926.6,1354.97 h -136.9 c -79.7,0 -121.3,-30.93 -166.6,-138.24 h -2.3 l 13.1,138.24 H 11373 l -135.7,-854.849 h 299.2 c 0,0 6.9,114.59 20,192.801 l 47.6,291.008 c 16.7,100.03 48.8,132.77 142.8,132.77 h 27.4 c 51.1,0 113,-4.88 113,-4.88 l 39.3,243.15"
          />
          <path
            class="black"
            d="m 12721.9,1354.97 h -189.2 c -17.8,-58.2 -242.7,-300.11 -242.7,-300.11 l 99.9,609.32 H 12100 L 11919,500.121 h 297.8 l 38.7,340.121 57.1,58.199 64.2,-398.32 h 318.8 c 0,0 -9.4,74.57 -18.9,118.219 l -116.6,536.55 161.8,200.08"
          />
          <path
            class="black"
            d="m 4754.35,4083.41 h 888.66 l -119.99,-469.26 h -888.65 l 119.98,469.26"
          />
          <path
            class="black"
            d="m 5491.19,3455.61 h -887.58 c 0,0 -309.91,-1237.42 -351.41,-1399.63 L 3878.73,562.07 c -33.96,-143.347 -83,-203.718 -230.13,-203.718 h -456.47 c -116.94,0 -254.66,8.16 -254.66,8.16 L 2901.65,11.2813 h 1229.84 c 396.1,0 543.24,166.0077 656.41,618.6987 l 376.58,1518.83 326.71,1306.8"
          />
          <path
            class="orange"
            d="m 0,2580.47 c 68.3672,-101.56 162.145,-187.25 305.137,-223.53 408.636,-103.71 966.143,55.82 1477.683,264.49 502.89,205.13 827.02,397.43 1341.75,678.22 531.11,289.73 825.84,463.13 1337.08,786.61 L 4214.76,3152.05 C 3673.32,2835.68 3310.4,2676.87 2764.78,2456.85 2313.31,2274.8 1999.04,2153.43 1573.04,2085.66 1170.48,2021.61 869.004,1994.28 528.18,2108.43 360.625,2164.55 251.313,2225.5 134.715,2333.12 54.0039,2407.61 31.2305,2475.16 0,2580.47"
          />
          <path
            class="orange"
            d="m 35.0977,2010.92 c 57.3906,-88.66 99.6363,-134.77 182.9723,-199.65 150.055,-116.81 271.758,-126.81 456.871,-170.31 353.219,-83.04 797.139,12.06 1291.039,120.62 468.86,103.06 718.1,222.99 1163.25,402.76 372.29,150.35 579.2,244.25 937.47,425.45 L 3867.1,1816.87 C 3405.3,1637.25 3090.27,1557.92 2648.3,1458.21 2163.08,1348.75 1838.27,1293.43 1381.49,1310.14 1013.82,1323.6 724.863,1365.5 460.355,1513.59 307.441,1599.22 216.488,1662.56 123.805,1811.27 78.75,1883.58 58.5586,1929 35.0977,2010.92"
          />
          <path
            class="orange"
            d="m 240.23,1359.96 c 220.122,-193.59 378.235,-279.52 660.426,-358.88 234.904,-66.049 379.254,-50.517 623.224,-55.31 507.14,-9.93 794.63,54.52 1290.6,160.92 369.52,79.27 576.14,137.85 932.29,264.35 L 3591.53,775.543 C 3146.21,644.152 2847.94,614.652 2416.89,581.75 1999.05,549.852 1579.31,570.402 1175.97,692.531 850.941,790.953 602.887,929.152 416.043,1117.57 333.813,1200.49 298.031,1258.5 240.23,1359.96"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "LogoJfnet.vue",
  props: ["width"],
};
</script>

<style scoped>
svg {
  display: block;
}
.black {
  fill: #040606;
  fill-opacity: 1;
  fill-rule: evenodd;
  stroke: none;
}
.theme--dark .black {
  fill: #ffffff !important;
}
.orange {
  fill: #f68c1f;
  fill-opacity: 1;
  fill-rule: evenodd;
  stroke: none;
}
</style>
