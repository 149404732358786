
import { defineComponent } from "vue";

export default defineComponent({
  name: "ToolTip",
  model: {
    prop: "active",
    event: "input",
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
});
