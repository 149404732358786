
import DeploymentsSubNav from "@/components/Navigation/DeploymentsSubNav.vue";
import { defineComponent } from "vue";
import { useDeploymentStore } from "@/store/deployment";
import { DataTableHeader } from "vuetify";
import ToolTip from "@/components/ToolTip.vue";
import { CronJob } from "@/api";
import ModalConfirm from "@/components/ModalConfirm.vue";

export default defineComponent({
  name: "DeploymentCronJobs",
  components: { ModalConfirm, ToolTip, DeploymentsSubNav },
  props: {
    deploymentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      remove: {
        confirm: false as boolean,
        loading: false as boolean,
        item: null as null | CronJob,
      },
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          width: "70px",
        },
        {
          text: "Active",
          value: "active",
          align: "center",
          width: "90px",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Cron",
          value: "cron",
          class: "nowrap",
          width: "150px",
        },
        {
          text: "Command",
          value: "command",
          width: "300px",
        },
        {
          text: "Actions",
          value: "action",
          align: "right",
          width: "300px",
        },
      ] as Array<DataTableHeader>,
    };
  },
  methods: {
    removeCronJob(id: number): void {
      console.log("remove #" + id);
    },
  },
  setup() {
    return {
      deploymentStore: useDeploymentStore(),
    };
  },
});
