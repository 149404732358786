var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-autocomplete',{attrs:{"items":_vm.domains,"clearable":"","item-text":"name","item-value":"id","label":"Domain Search","prepend-icon":"mdi-database-search"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-content',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c('v-col',[_c('small',[_vm._v(" "+_vm._s(data.item.deployment.project.name)+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  name: 'Deployment',
                  params: { deploymentId: data.item.deployment.id },
                })}}},[_vm._v(" Deployment #"+_vm._s(data.item.deployment.id)+" ")])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  name: 'Project',
                  params: { projectId: data.item.deployment.project.id },
                })}}},[_vm._v(" Project #"+_vm._s(data.item.deployment.project.id)+" ")])],1)],1)],1)]]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }