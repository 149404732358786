
import { defineComponent, PropType } from "vue";
import { RouteTreeElement } from "@/router/tree";

export default defineComponent({
  name: "SubMenuItem",
  props: {
    item: Object as PropType<RouteTreeElement>,
  },
});
