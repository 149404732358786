import { defineStore } from "pinia";
import { Deployment, DeploymentsApi, Project, ProjectsApi } from "@/api";

export const useProjectStore = defineStore("project", {
  state: () => ({
    projectId: null as number | null,
    details: null as Project | null,
    deployments: null as Array<Deployment> | null,
    loading: false,
  }),
  actions: {
    load: async function (projectId: number): Promise<void> {
      this.details = null;
      this.deployments = null;
      this.loading = true;

      await Promise.allSettled([
        new ProjectsApi().getProjectsDetails(projectId).then((response) => {
          this.details = response.data;
        }),
        new DeploymentsApi().getDeploymentsList(projectId).then((response) => {
          this.deployments = response.data;
        }),
      ]);

      this.loading = false;
      this.projectId = projectId;
    },
  },
});
