var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-tabs',{staticClass:"rounded-0",attrs:{"dark":""}},[_c('v-tab',{attrs:{"to":{
        name: 'Deployment',
        params: { deploymentId: _vm.deploymentId },
      },"exact-path":""}},[_vm._v(" Main ")]),_c('v-tab',{attrs:{"to":{
        name: 'DeploymentVolumes',
        params: { deploymentId: _vm.deploymentId },
      }}},[_vm._v(" Volumes "),_c('v-chip',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.deploymentStore.deployment?.volumes.length ?? "-")+" ")])],1),_c('v-tab',{attrs:{"to":{
        name: 'DeploymentEnvVars',
        params: { deploymentId: _vm.deploymentId },
      }}},[_vm._v(" Env Vars "),_c('v-chip',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.deploymentStore.deployment?.environmentVariables.length ?? "-")+" ")])],1),_c('v-tab',{attrs:{"to":{
        name: 'DeploymentDomains',
        params: { deploymentId: _vm.deploymentId },
      }}},[_vm._v(" Domains "),_c('v-chip',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.deploymentStore.deployment?.domains.length ?? "-")+" ")])],1),_c('v-tab',{attrs:{"to":{
        name: 'DeploymentCronJobs',
        params: { deploymentId: _vm.deploymentId },
      }}},[_vm._v(" Cronjobs "),_c('v-chip',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.deploymentStore.deployment?.cronJobs.length ?? "-")+" ")])],1),_c('v-tab',{attrs:{"to":{
        name: 'DeploymentTrigger',
        params: { deploymentId: _vm.deploymentId },
      }}},[_vm._v(" Trigger "),_c('v-chip',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.deploymentStore.deployment?.triggers.length ?? "-")+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }