
import { Certificate, CertificatesApi } from "@/api";
import { DataTableHeader } from "vuetify";
import { defineComponent } from "vue";
import ModalConfirm from "@/components/ModalConfirm.vue";

export default defineComponent({
  name: "CertificatesView",
  components: { ModalConfirm },
  data() {
    return {
      headers: [
        { text: "Name", value: "subject" },
        {
          text: "Issuer",
          value: "issuer",
          width: "350px",
          align: "center",
        },
        {
          text: "Valid from",
          value: "validFrom",
          width: "160px",
          align: "right",
          filterable: false,
        },
        {
          text: "Valid till",
          value: "validTo",
          width: "160px",
          align: "right",
          filterable: false,
        },
        {
          text: "Days left",
          value: "validDaysLeft",
          width: "110px",
          align: "center",
          filterable: false,
        },
        {
          text: "Domains",
          value: "coveredDomains",
          align: "right",
          sortable: false,
        },
        {
          text: "Type",
          value: "type",
          width: "150px",
          align: "center",
        },
        {
          text: "",
          value: "actions",
          width: "250px",
          align: "right",
          filterable: false,
          sortable: false,
        },
      ] as Array<DataTableHeader>,
      sortBy: "validDaysLeft",
      sortDesc: false,
      certificates: [] as Array<Certificate>,
      remove: {
        confirm: false as boolean,
        loading: false as boolean,
        item: null as null | Certificate,
      },
      renew: {
        item: null as null | Certificate,
      },
      certificatesSearch: "",
      manualActive: true,
      loading: true,
    };
  },
  computed: {
    filteredCertificates(): Array<Certificate> {
      return this.certificates.filter((certificate) =>
        this.manualActive ? certificate.manual : true
      );
    },
    removeMessage(): string {
      return (
        "Do you really want to delete certificate " +
        this.remove.item?.subject +
        "?"
      );
    },
  },
  methods: {
    async load() {
      this.loading = true;
      this.certificates = (await new CertificatesApi().getCertificates()).data;
      this.loading = false;
    },
    addCertificate() {
      alert("todo: implement upload form");
    },
    removeCertificate() {
      this.remove.loading = true;
      new CertificatesApi()
        .deleteCertificatesRemove(this.remove.item?.id as number)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(
              "remove certificate " +
                this.remove.item?.subject +
                " was successful"
            );
          }
        })
        .finally(() => {
          this.remove.loading = false;
          this.remove.confirm = false;
          this.load();
        });
    },
    renewCertificate(item: Certificate) {
      new CertificatesApi()
        .putCertificatesRenew(item?.id as number)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(
              "renew certificate " + item?.subject + " started"
            );
          }
        })
        .finally(() => {
          this.load();
        });
    },
  },
  mounted() {
    this.load();
  },
});
