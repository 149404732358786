import { RouteConfig, RouteMeta } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import TimeSpentView from "@/views/TimeSpentView.vue";
import AlertsControlView from "@/views/AlertsControlView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import DeploymentDetails from "@/components/Kubernetes/DeploymentDetails.vue";
import RevisionsList from "@/components/Kubernetes/RevisionsList.vue";
import { MenuGroup } from "@/router/tree";
import ProjectsView from "@/views/ProjectsView.vue";
import ProjectView from "@/views/ProjectView.vue";
import DeploymentDomains from "@/components/Kubernetes/Deployment/DeploymentDomains.vue";
import DeploymentVolumes from "@/components/Kubernetes/Deployment/DeploymentVolumes.vue";
import DeploymentCronJobs from "@/components/Kubernetes/Deployment/DeploymentCronJobs.vue";
import DeploymentTrigger from "@/components/Kubernetes/Deployment/DeploymentTrigger.vue";
import DeploymentEnvVars from "@/components/Kubernetes/Deployment/DeploymentEnvVars.vue";
import CertificatesView from "@/views/CertificatesView.vue";

export const menuGroups = [
  {
    name: "Kubernetes",
    icon: "mdi-kubernetes",
  } as MenuGroup,
  {
    name: "Test",
    icon: "mdi-ab-testing",
    parent: "Kubernetes",
  } as MenuGroup,
];

export const routes: Array<RouteConfig> = [
  {
    name: "Projects",
    path: "/projects",
    component: ProjectsView,
    meta: {
      role: "ROLE_F_TOOLS_DEPLOYMENTS",
      icon: "mdi-format-list-checks",
    } as RouteMeta,
  },
  {
    path: "/project/:projectId",
    name: "Project",
    props: (route) => {
      return { projectId: Number(route.params.projectId) };
    },
    meta: {
      parent: "Projects",
    } as RouteMeta,
    component: ProjectView,
  },
  {
    path: "/deployment/:deploymentId",
    name: "Deployment",
    props: (route) => {
      return {
        deploymentId: Number(route.params.deploymentId),
      };
    },
    meta: {
      parent: "Project",
    } as RouteMeta,
    component: DeploymentDetails,
  },
  {
    path: "/deployment/:deploymentId/volumes",
    name: "DeploymentVolumes",
    props: (route) => {
      return {
        deploymentId: Number(route.params.deploymentId),
      };
    },
    meta: {
      parent: "Deployment",
    } as RouteMeta,
    component: DeploymentVolumes,
  },
  {
    path: "/deployment/:deploymentId/domains",
    name: "DeploymentDomains",
    props: (route) => {
      return {
        deploymentId: Number(route.params.deploymentId),
      };
    },
    meta: {
      parent: "Deployment",
    } as RouteMeta,
    component: DeploymentDomains,
  },
  {
    path: "/deployment/:deploymentId/cronjobs",
    name: "DeploymentCronJobs",
    props: (route) => {
      return {
        deploymentId: Number(route.params.deploymentId),
      };
    },
    meta: {
      parent: "Deployment",
    } as RouteMeta,
    component: DeploymentCronJobs,
  },
  {
    path: "/deployment/:deploymentId/trigger",
    name: "DeploymentTrigger",
    props: (route) => {
      return {
        deploymentId: Number(route.params.deploymentId),
      };
    },
    meta: {
      parent: "Deployment",
    } as RouteMeta,
    component: DeploymentTrigger,
  },
  {
    path: "/deployment/:deploymentId/env",
    name: "DeploymentEnvVars",
    props: (route) => {
      return {
        deploymentId: Number(route.params.deploymentId),
      };
    },
    meta: {
      parent: "Deployment",
    } as RouteMeta,
    component: DeploymentEnvVars,
  },
  {
    path: "/deployment/:deploymentId/revisions",
    name: "Revisions",
    props: (route) => {
      return {
        deploymentId: Number(route.params.deploymentId),
      };
    },
    meta: {
      parent: "Deployment",
    } as RouteMeta,
    component: RevisionsList,
  },
  {
    path: "/certificates",
    name: "Certificates",
    meta: {
      icon: "mdi-certificate",
    } as RouteMeta,
    component: CertificatesView,
  },
  {
    path: "/alerts",
    name: "Alerts",
    component: AlertsControlView,
    meta: {
      role: "ROLE_F_TOOLS_ALERTS",
      icon: "mdi-bell-ring",
    } as RouteMeta,
  },
  {
    path: "/timespent",
    name: "TimeSpent Auswertung",
    component: TimeSpentView,
    meta: {
      role: "ROLE_F_TOOLS_TIMESPENT",
      icon: "mdi-chart-bar",
    } as RouteMeta,
  },
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFoundView,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];
