import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "@/router/routes";
import { beforeEachGuard } from "@/router/security";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEachGuard);

export default router;
