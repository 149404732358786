
import { defineComponent } from "vue";
import { useMainStore } from "@/store/main";
import SubMenuItem from "@/components/Navigation/SubMenuItem.vue";
import { getRoutesTree, RouteTree, RouteTreeElement } from "@/router/tree";
import BreadcrumbDisplay from "@/components/Navigation/BreadcrumbDisplay.vue";
import LogoJfnet from "@/components/LogoJfnet.vue";

export default defineComponent({
  name: "NavigationBar",
  components: { LogoJfnet, BreadcrumbDisplay, SubMenuItem },
  props: {
    source: String,
  },
  computed: {
    breadcrumb(): Array<RouteTreeElement> | null {
      const result = [] as Array<RouteTreeElement>;

      let activeTreeElement =
        this.routeTree?.routes[this.$route.name ?? "Home"];

      if (typeof activeTreeElement === "undefined") {
        return [];
      }
      result.push(activeTreeElement);
      while (activeTreeElement.parent) {
        activeTreeElement = activeTreeElement.parent;
        if (activeTreeElement.route) {
          result.push(activeTreeElement);
        }
      }
      return result.reverse();
    },
  },
  data() {
    return {
      drawer: true,
      routeTree: null as null | RouteTree,
    };
  },
  setup() {
    return {
      mainStore: useMainStore(),
    };
  },
  async mounted() {
    this.routeTree = await getRoutesTree(this.$route.name);
  },
});
