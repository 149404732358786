import { menuGroups, routes } from "@/router/routes";
import { RouteConfig } from "vue-router";
import { checkRouteAccess } from "@/router/security";

export interface RouteTree {
  routes: RouteList;
  tree: Array<RouteTreeElement>;
  menu: Array<RouteTreeElement>;
}

interface RouteList {
  [key: string]: RouteTreeElement;
}

export interface RouteTreeElement {
  route: RouteConfig | null;
  menuGroup: MenuGroup | null;
  active: boolean | null;
  children: Array<RouteTreeElement>;
  parent: RouteTreeElement | null;
}

export interface MenuGroup {
  icon: string | null;
  name: string;
  parent: string | null;
}

function getRouteId(route: RouteConfig) {
  return route.name ?? route.path;
}
function getMenuGroupId(group: string) {
  return "Group-" + group;
}

function createHashTable(): RouteList {
  const hashTable = Object.create(null);
  routes.forEach(
    (route) =>
      (hashTable[getRouteId(route)] = {
        route: route,
        menuGroup: null,
        active: null,
        children: [],
        parent: null,
      } as RouteTreeElement)
  );

  menuGroups.forEach((menuGroup) => {
    hashTable[getMenuGroupId(menuGroup.name)] = {
      route: null,
      menuGroup: menuGroup,
      active: null,
      children: [],
      parent: null,
    } as RouteTreeElement;
  });
  return hashTable;
}

export async function getRoutesTree(
  currentRoute: string | null | undefined
): Promise<RouteTree> {
  const routeTree = [] as Array<RouteTreeElement>;
  const menuTree = [] as Array<RouteTreeElement>;
  const groupsAdded = [] as Array<string>;

  const hashTable = createHashTable();

  for (const route of routes) {
    const routeId = getRouteId(route);
    if (route.meta?.parent) {
      if (typeof hashTable[route.meta?.parent] === "undefined") {
        console.error(
          `ERROR: Parent route '${route.meta?.parent}' for route '${routeId}' not found!`
        );
        continue;
      }

      hashTable[route.meta?.parent].children.push(hashTable[routeId]);
      hashTable[routeId].parent = hashTable[route.meta?.parent];
      continue;
    }

    const hashTableElement = (hashTable[routeId] as RouteTreeElement).route
      ?.meta;

    if (
      hashTableElement?.icon &&
      (await checkRouteAccess(hashTableElement.role))
    ) {
      if (hashTableElement.menuGroup) {
        const menuGroup =
          hashTable[getMenuGroupId(hashTableElement.menuGroup)].menuGroup;

        if (menuGroup === null) {
          console.error(
            `ERROR: menu group '${hashTableElement.menuGroup}' for route '${routeId}' not found!`
          );
          continue;
        }

        hashTable[getMenuGroupId(hashTableElement.menuGroup)].children.push(
          hashTable[routeId]
        );
        hashTable[routeId].parent =
          hashTable[getMenuGroupId(hashTableElement.menuGroup)];

        if (groupsAdded.indexOf(hashTableElement.menuGroup) === -1) {
          groupsAdded.push(hashTableElement.menuGroup);
          if (menuGroup.parent) {
            hashTable[getMenuGroupId(menuGroup.parent)].children.push(
              hashTable[getMenuGroupId(hashTableElement.menuGroup)]
            );
            hashTable[getMenuGroupId(hashTableElement.menuGroup)].parent =
              hashTable[getMenuGroupId(menuGroup.parent)];
          } else {
            menuTree.push(
              hashTable[getMenuGroupId(hashTableElement.menuGroup)]
            );
          }
        }
      } else {
        menuTree.push(hashTable[routeId]);
      }
    }
    routeTree.push(hashTable[routeId]);
  }

  if (currentRoute) {
    let activeTreeElement = hashTable[currentRoute];
    while (activeTreeElement.parent) {
      activeTreeElement = activeTreeElement.parent;
      if (activeTreeElement.menuGroup) {
        activeTreeElement.active = true;
      }
    }
  }

  return {
    routes: hashTable,
    tree: routeTree,
    menu: menuTree,
  } as RouteTree;
}
