
import { defineComponent } from "vue";
import DeploymentList from "@/components/Kubernetes/DeploymentList.vue";
import { useProjectStore } from "@/store/project";
import { openExternal } from "@/tools/openNewWindow";

export default defineComponent({
  name: "ProjectView",
  components: { DeploymentList },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    openExternal(url: string) {
      openExternal(url);
    },
  },
  setup() {
    return {
      projectStore: useProjectStore(),
    };
  },
  mounted() {
    this.projectStore.load(this.projectId);
  },
});
