
import { defineComponent } from "vue";
import { Domain, DomainsApi } from "@/api";

export default defineComponent({
  name: "DomainSearch",
  data() {
    return {
      domains: null as null | Array<Domain>,
    };
  },
  async mounted() {
    this.domains = (await new DomainsApi().getDomains()).data;
  },
});
