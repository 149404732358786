
import { defineComponent } from "vue";
import { parseTime } from "@/tools/humanTime";

export default defineComponent({
  name: "IssueList",
  props: {
    item: {},
  },
  data() {
    return {
      issueHeaders: [
        { text: "Zeit", value: "spent", width: "200px", align: "end" },
        { text: "Titel", value: "title" },
        { text: "Link", value: "path" },
      ],
    };
  },
  methods: {
    humanTime(time: number) {
      return parseTime(time);
    },
  },
});
