import { defineStore } from "pinia";
import { Deployment, DeploymentsApi } from "@/api";

export const useDeploymentStore = defineStore("deployment", {
  state: () => ({
    deploymentId: null as number | null,
    deployment: null as Deployment | null,
    loading: false,
  }),
  actions: {
    load: async function (deploymentId: number): Promise<void> {
      if (this.deploymentId === deploymentId) {
        return;
      }
      this.deployment = null;
      this.loading = true;

      await Promise.allSettled([
        new DeploymentsApi()
          .getDeploymentsRead(deploymentId)
          .then((response) => {
            this.deployment = response.data;
          }),
      ]);

      this.loading = false;
      this.deploymentId = deploymentId;
    },
  },
});
