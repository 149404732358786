
import { defineComponent } from "vue";
import { ProjectsApi, ProjectSearch } from "@/api";
import { DataTableHeader } from "vuetify";
import fuzzysort from "fuzzysort";
import ModalConfirm from "@/components/ModalConfirm.vue";
import { openExternal } from "@/tools/openNewWindow";

export default defineComponent({
  name: "ProjectList",
  components: { ModalConfirm },
  data() {
    return {
      headers: [
        { text: "Project", value: "name" },
        {
          text: "Deployments",
          value: "deploymentCount",
          width: "40px",
          align: "center",
          filterable: false,
        },
        {
          text: "Active",
          value: "active",
          width: "40px",
          align: "center",
          filterable: false,
        },
        {
          text: "",
          value: "gitlabUrl",
          width: "40px",
          align: "center",
          filterable: false,
        },
        {
          text: "",
          value: "action",
          width: "40px",
          align: "center",
          filterable: false,
        },
        {
          text: "",
          value: "open",
          width: "40px",
          align: "center",
          filterable: false,
        },
      ] as Array<DataTableHeader>,
      projects: [] as Array<ProjectSearch>,
      remove: {
        confirm: false as boolean,
        loading: false as boolean,
        item: null as null | ProjectSearch,
      },
      search: "",
      filterActive: true,
      filterWithDeployment: true,
      shownProjects: [] as Array<ProjectSearch>,
      loading: true,
    };
  },
  computed: {
    filteredProjects(): Array<ProjectSearch> {
      let result = this.projects
        .filter((project) => (this.filterActive ? project.active : true))
        .filter((project) =>
          this.filterWithDeployment ? project.deploymentCount ?? 0 > 0 : true
        );
      if (this.search !== "") {
        result = fuzzysort
          .go(this.search ?? "", result, {
            key: "name",
          })
          .map((result) => result.obj);
      }
      return result;
    },
    removeMessage(): string {
      return (
        "Do you really want to " +
        this.removeVerb(this.remove.item) +
        " project " +
        this.remove.item?.name +
        "?"
      );
    },
  },
  methods: {
    openExternal(url: string) {
      openExternal(url);
    },
    openProject(project: number) {
      this.$router.push({
        name: "Project",
        params: { projectId: project.toString() },
      });
    },
    getShownRows(rows: Array<ProjectSearch>) {
      this.shownProjects = rows;
    },
    removeProject() {
      this.remove.loading = true;
      new ProjectsApi()
        .deleteProjectsRemove(this.remove.item?.id as number)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(
              "Project " + this.removeVerb(this.remove.item) + " was successful"
            );
          }
        })
        .finally(() => {
          this.remove.loading = false;
          this.remove.confirm = false;
          this.load();
        });
    },
    removeVerb(item: ProjectSearch | null): string {
      if (item === null) {
        return "";
      }
      return item.active ? "reset" : "delete";
    },
    async load() {
      this.loading = true;
      this.projects = (await new ProjectsApi().getProjects()).data;
      this.loading = false;
    },
  },
  mounted() {
    this.load();
  },
});
