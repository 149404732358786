<template>
  <v-container fill-height fluid>
    <v-row align="center" class="fill-height">
      <v-col class="col-12">
        <LogoJfnet width="600px"></LogoJfnet>
        <p class="text-center caption">Tools - {{ mainStore.env.version }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useMainStore } from "@/store/main";
import LogoJfnet from "@/components/LogoJfnet.vue";

export default {
  name: "HomeView",
  components: { LogoJfnet },
  setup() {
    return {
      mainStore: useMainStore(),
    };
  },
};
</script>

<style lang="scss" scoped></style>
