
import NavigationBar from "@/components/NavigationBar.vue";
import ApiErrorCatcher from "@/components/ApiErrorCatcher.vue";
import HomeView from "@/views/HomeView.vue";
import { defineComponent } from "vue";
import { useMainStore } from "@/store/main";

export default defineComponent({
  name: "app",
  props: {
    source: String,
  },
  components: {
    HomeView,
    ApiErrorCatcher,
    NavigationBar,
  },
  setup() {
    const mainStore = useMainStore();
    mainStore.load();
    return {
      mainStore,
    };
  },
});
