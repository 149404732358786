import { render, staticRenderFns } from "./ModalConfirm.vue?vue&type=template&id=c5d5b134&scoped=true&"
import script from "./ModalConfirm.vue?vue&type=script&lang=ts&"
export * from "./ModalConfirm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5d5b134",
  null
  
)

export default component.exports