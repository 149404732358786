
import { defineComponent, PropType } from "vue";
import { RouteTreeElement } from "@/router/tree";

export default defineComponent({
  name: "BreadcrumbDisplay",
  props: {
    element: {
      type: Object as PropType<RouteTreeElement>,
      required: true,
    },
    chevron: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    navigate() {
      this.$router.push({ name: this.element.route?.name });
    },
  },
});
