import humanizeDuration, { HumanizerOptions } from "humanize-duration";

const humanizeDurationOptions: HumanizerOptions = {
  language: navigator.language.split(/[-_]/)[0],
  units: ["h", "m"],
};

export function parseTime(time: number): string {
  return humanizeDuration(time * 1000, humanizeDurationOptions);
}
