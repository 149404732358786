
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalConfirm",
  model: {
    prop: "active",
    event: "input",
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Are you sure?",
    },
    button: {
      type: String,
      default: "Confirm",
    },
    payload: {
      type: String,
      default: "",
    },
  },
  methods: {
    confirm() {
      this.$emit("confirmed", this.payload);
    },
    closeEvent() {
      if (!this.loading) {
        this.$emit("input", false);
      }
    },
  },
});
