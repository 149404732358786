
import { defineComponent } from "vue";
import { useDeploymentStore } from "@/store/deployment";

export default defineComponent({
  name: "DeploymentsSubNav",
  props: {
    deploymentId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      deploymentStore: useDeploymentStore(),
    };
  },
  mounted() {
    this.deploymentStore.load(this.deploymentId);
  },
});
