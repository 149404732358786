
import { defineComponent } from "vue";
import DeploymentsSubNav from "@/components/Navigation/DeploymentsSubNav.vue";

export default defineComponent({
  name: "DeploymentDetails",
  components: { DeploymentsSubNav },
  props: {
    deploymentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: "Main", content: "Tab 1 Content" },
        { tab: "Env Vars", content: "Tab 3 Content" },
        { tab: "Cron Jobs", content: "Tab 5 Content" },
        { tab: "Trigger", content: "Tab 6 Content" },
      ],
    };
  },
});
