import { NavigationGuardNext, Route } from "vue-router";
import { useMainStore } from "@/store/main";

export function beforeEachGuard(
  to: Route,
  from: Route,
  next: NavigationGuardNext
) {
  let access = true;
  to.matched.forEach((route) => {
    if (!checkRouteAccess(route.meta?.role)) {
      access = false;
    }
  });
  return access ? next() : next({ name: "Home" });
}

export async function checkRouteAccess(
  role: string | undefined | null
): Promise<boolean> {
  if (!role) {
    return true;
  }
  const mainStore = useMainStore();
  return await mainStore.hasAccess(role);
}
